import React from "react"
import styled from "styled-components"
import Theme from "../../components/Theme"

const { Colors } = Theme
const CodePenWrapper = styled.div`
  background-color: ${Colors.lighter};
`

const CodePen = ({ children }) => {
  return <CodePenWrapper>{children}</CodePenWrapper>
}

export default CodePen
